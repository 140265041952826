<template>
  <v-container
    fill-height
  >
      <v-row>

          <v-col
            v-if="!formDone"
            cols="12"
          >
            <v-card
                class="mx-auto"
                max-width="944"
            >
                <v-card-title>
                    <h1>Je gegevens</h1>
                    <v-spacer></v-spacer>
                    <Privacy />
                </v-card-title>
                <v-card-text>
                    <v-form
                        ref="form"
                        v-model="form.valid"
                    >
                        <v-select
                            v-model="form.salutation"
                            :items="form.salutationItems"
                            label="Aanhef"
                        ></v-select>

                        <v-text-field
                            v-model="form.firstname"
                            :rules="[v => !!v || 'Voornaam is verplicht']"
                            label="Voornaam*"
                            required
                        ></v-text-field>

                        <v-text-field
                            v-model="form.prefix"
                            label="Tussenvoegsel"
                        ></v-text-field>

                        <v-text-field
                            v-model="form.lastname"
                            label="Achternaam"
                        ></v-text-field>

                        <v-text-field
                            v-model="form.email"
                            :rules="[v => !!v || 'Email is verplicht',]"
                            label="Email*"
                            required
                        ></v-text-field>

                        <v-text-field
                            v-model="form.phonenumber"
                            label="Telefoonnummer"
                        ></v-text-field>

                        <v-text-field
                            placeholder="Bijvoorbeeld 1234"
                            type="number"
                            v-model="form.postalcode"
                            label="Postcode"
                            max="4"
                            oninput="if(Number(this.value.length) > Number(this.max)) this.value = this.value.substr(0,4);"
                        ></v-text-field>

                        <v-select
                            v-model="form.ageCategory"
                            :items="form.ageCategoryItems"
                            label="Leeftijd"
                        ></v-select>

                        <v-checkbox
                            v-model="form.receiveCall"
                            label="Ik zou graag eenmalig gebeld willen worden over de uitslag van mijn test"
                        ></v-checkbox>

                        <v-checkbox
                            v-model="form.subscribe"
                            label="Ik wil op de hoogte blijven over ontwikkelingen en tips op het gebied van Burn Outs"
                        ></v-checkbox>

                        <v-checkbox
                            v-model="form.privacy"
                            :rules="[v => !!v || 'Accepteer alstublieft de voorwaarden']"
                            label="Ik ga akkoord met de privacy overeenkomsten*"
                            required
                        ></v-checkbox>
                    </v-form>
                </v-card-text>

                <v-card-actions
                    class="d-flex justify-center"
                >
                    <v-btn
                        :disabled="!form.valid"
                        color="success"
                        class="mr-4"
                        @click="validate"
                        
                    >
                        Naar uitslag
                        <v-icon
                            right
                            dark
                        >
                            mdi-file-send
                        </v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
          </v-col>
          <v-col
            v-else
            cols="12"
            class="text-center"
          >
              <h1>Bedankt.</h1>
              <h3>
                  in een paar seconden ontvang je mijn analyse van jouw burn out test
              </h3>
              {{CLOCK}}
              <p>
                  of klik <a @click="goToPage" href="#">hier</a>
              </p>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import Privacy from './Privacy.vue';

export default {
    name: "Result",
    props: ["scores"],
    components: {
        Privacy
    },
    data: () => ({
        CLOCK: 5,
        formDone: false,
        form: {
            valid: true,
            salutation: "O",
            salutationItems: [{text:"Onbekend", value: "O"},{text:"Meneer", value: "M"}, {text:"Mevrouw", value: "V"}],
            firstname: "",
            prefix: "",
            lastname: "",
            phonenumber: "",
            receiveCall: false,
            email: "",
            ageCategory: "",
            ageCategoryItems: ["< 20 jaar", "20 - 30 jaar", "30 - 40 jaar", "40 - 50 jaar", "50 - 60 jaar", "> 60 jaar"],
            postalcode: null,
            privacy: false,
            subscribe: false
        }
    }),
    methods: {
        timer(seconds, cb) {
            const that = this;
            var remaningTime = seconds;
            window.setTimeout(function() {
                cb();
                if (remaningTime > 0) {
                    that.timer(remaningTime - 1, cb); 
                } else {
                    that.goToPage()
                }
            }, 1000);
        },
        callback(){
            this.CLOCK--;
        },
        goToPage(){
            let result = 0;
            for (const score of this.scores) {
                result += score;
            }

            if (result <= 28){
                window.open("https://www.burnoutpoli.com/b/", "_parent");
            } else if(result <= 56){
                window.open("https://www.burnoutpoli.com/u/","_parent");
            } else if(result <= 70){
                window.open("https://www.burnoutpoli.com/r/", "_parent");
            } else if(result <= 85 || result >= 85 ){
                window.open("https://www.burnoutpoli.com/n/", "_parent");
            }
        },
        validate () {
            if(this.$refs.form.validate()){
                fetch("https://burnouttest.blueshift.today/api/burnouttest_v2", {
                    method: "POST",
                    body: JSON.stringify({...this.form, scores: this.scores}),
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                
                this.formDone = true;

                this.timer(this.CLOCK, this.callback);
            }
        },
    },
    mounted(){
    }
}
</script>

<style>

</style>