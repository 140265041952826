<template>
  <v-dialog
      scrollable
      v-model="dialog"
      width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          v-bind="attrs"
          v-on="on"
        >
          Ons Privacybeleid <v-icon>mdi-arrow-expand</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span style="color: #16B7F7;" class="headline">Privacybeleid</span>
        </v-card-title>
        <v-card-text>
            <h3>Begripsbepalingen</h3>
            <p>In dit reglement wordt verstaan onder:</p>
            <ul>
                <li>De hulpverlener, <b>Burn Out Poli</b>, Den Haag.</li>
                <li>De wet: de Wet bescherming persoonsgegevens en vanaf 25 mei 2018 de Algemene Verordening Gegevensbescherming (AVG);</li>
                <li>Persoonsgegeven: elk gegeven over een geïdentificeerde of identificeerbare natuurlijke persoon;</li>
                <li>Verwerking van persoonsgegevens: elke handeling of elk geheel van handelingen met betrekking tot persoonsgegevens. Daaronder wordt in ieder geval verstaan het verzamelen, vastleggen, ordenen, bewaren, bijwerken, wijzigen, opvragen, raadplegen, gebruiken, verstrekken door middel van doorzending, verspreiding of enige andere vorm van terbeschikkingstelling, samenbrengen, met elkaar in verband brengen, alsmede het afschermen, uitwisselen of vernietigen van gegevens;</li>
                <li>Bestand: elk samenhangend geheel van persoonsgegevens, ongeacht of dit geheel van gegevens bij elkaar of gescheiden van elkaar wordt verzameld, dat volgens bepaalde criteria toegankelijk is en betrekking heeft op verschillende personen;</li>
                <li>Verantwoordelijke: degene die alleen of samen met anderen het doel van en de middelen voor de verwerking van persoonsgegevens vaststelt. Verantwoordelijke kan zijn een natuurlijke persoon, een rechtspersoon of een bestuursorgaan;</li>
                <li>Bewerker: degene die ten behoeve van de verantwoordelijke persoonsgegevens verwerkt, zonder aan zijn rechtstreekse gezag te zijn onderworpen;</li>
                <li>Betrokkene: degene op wie een persoonsgegeven betrekking heeft;</li>
                <li>Derde: ieder ander dan de betrokkene, de verantwoordelijke, de bewerker of enig persoon die onder rechtstreeks gezag van de verantwoordelijke of de bewerker gemachtigd is om persoonsgegevens te verwerken;</li>
                <li>Ontvanger: degene aan wie de persoonsgegevens worden verstrekt;</li>
                <li>Toestemming van de betrokkene: elke vrije, specifieke en op informatie berustende wilsuiting waarmee de betrokkene aanvaardt dat persoonsgegevens over hem worden verwerkt;</li>
                <li>Verstrekken van persoonsgegevens: het bekend maken of ter beschikking stellen van persoonsgegevens;</li>
                <li>Verzamelen van persoonsgegevens: het verkrijgen van persoonsgegevens.</li>
            </ul>
            <h3>Bereik</h3>
            <p>Dit reglement is van toepassing op de geheel of gedeeltelijk geautomatiseerde verwerking van persoonsgegevens. Het is eveneens van toepassing op de niet geautomatiseerde verwerking van persoonsgegevens die in een bestand zijn opgenomen of die bestemd zijn om daarin te worden opgenomen.</p>
            <p>Dit reglement heeft betrekking op de verwerkingen van persoonsgegevens van voornamelijk cliënten, maar kan ook van toepassing zijn op medewerkers.</p>
            <h3>Doel</h3>
            <p>Het doel van het verzamelen en het verwerken van persoonsgegevens is te beschikken over de gegevens die noodzakelijk zijn voor het realiseren van wettelijke doeleinden alsmede de doeleinden zoals die staan omschreven in de dienstomschrijving van de hulpverlener en het voeren van beleid en beheer in het kader van deze doeleinden.</p>
            <p>De doeleinden waarvoor gegevens worden verzameld en verwerkt, staan uitdrukkelijk omschreven in de dienstomschrijving die als bijlage is bijgevoegd.</p>
            <h3>Verantwoordelijkheid voor het beheer en aansprakelijkheid</h3>
            <p>De verantwoordelijke is verantwoordelijk voor het goed functioneren van de verwerking en het beheer van de gegevens; onder verantwoordelijkheid van de verantwoordelijke wordt doorgaans een beheerder belast met het feitelijke beheer van de persoonsgegevens, maar dit is niet verplicht bij kleinschalige persoonsgegevensverwerking zoals bij de hulpverlener het geval is.</p>
            <p>De verantwoordelijke draagt er zorg voor dat er passende technische en organisatorische maatregelen worden uitgevoerd ter beveiliging tegen enig verlies of enige vorm van onrechtmatige verwerking van gegevens.</p>
            <p>De in lid 1 bedoelde verantwoordelijkheid en het in lid 2 bepaalde geldt onverminderd indien de verwerking plaats vindt door een bewerker; dit wordt geregeld in een overeenkomst (of door middel van een andere rechtshandeling) tussen bewerker en verantwoordelijke.</p>
            <p>De verantwoordelijke is aansprakelijk voor de schade die of het nadeel dat wordt veroorzaakt door het niet-nakomen van de voorschriften uit de wet of dit reglement. De bewerker is aansprakelijk voor die schade of dat nadeel, voor zover die/dat is ontstaan door zijn handelen.</p>
            <h3>Rechtmatige verwerking</h3>
            <p>Persoonsgegevens worden op een transparante wijze en in overeenstemming met de wet en dit reglement op behoorlijke en zorgvuldige wijze verwerkt.</p>
            <p>Persoonsgegevens worden alleen voor de in dit reglement bedoelde doeleinden verzameld en worden niet verder verwerkt op een manier die onverenigbaar is met de doeleinden waarvoor ze zijn verkregen.</p>
            <p>Persoonsgegevens dienen - gelet op de doeleinden waarvoor zij worden verzameld of vervolgens worden verwerkt - toereikend en ter zake dienend te zijn; er dienen niet meer persoonsgegevens te worden verzameld of verwerkt dan voor het doel van de registratie nodig is.</p>
            <p>Persoonsgegevens mogen slechts worden verwerkt indien:</p>
            <ul>
                <li>de betrokkene voor de verwerking zijn ondubbelzinnige toestemming heeft verleend;</li>                                
                <li>de gegevensverwerking noodzakelijk is voor de uitvoering van een overeenkomst waarbij betrokkene partij is (bijvoorbeeld de arbeidsovereenkomst met betrokkene) of voor handelingen, op verzoek van betrokkene, die noodzakelijk zijn voor het sluiten van een overeenkomst;</li>
                <li>de gegevensverwerking noodzakelijk is om een wettelijke verplichting van de verantwoordelijke na te komen;</li>
                <li>de gegevensverwerking noodzakelijk is in verband met een vitaal belang van betrokkene;</li>
                <li>de gegevensverwerking noodzakelijk is met het oog op een belang van de verantwoordelijke of van een derde, tenzij dat belang strijdig is met het belang van degene van wie de gegevens worden verwerkt en dát belang voorgaat.</li>
            </ul>
            <p>De registratie van het burgerservicenummer vindt alleen dan plaats wanneer daarvoor een wettelijke basis bestaat en/of dat er door verantwoordelijke of verwerker een vorm van zorg aan betrokkene wordt verleend.</p>
            <p>Ieder die handelt onder het gezag van de verantwoordelijke of van de bewerker - en ook de bewerker zelf - verwerkt alleen persoonsgegevens in opdracht van de verantwoordelijke, behalve in geval van afwijkende wettelijke verplichtingen.</p>
            <p>De gegevens worden alleen verwerkt door personen die uit hoofde van ambt, beroep, wettelijk voorschrift, of op basis van een overeenkomst tot geheimhouding zijn verplicht.</p>
            <h3>Verwerking van persoonsgegevens</h3>
            <p>De verwerking vindt plaats door hulp- of dienstverleners in de maatschappelijke dienstverlening voor zover dat met het oog op een goede behandeling of verzorging van de betrokkene, dan wel beheer van de desbetreffende instelling of beroepspraktijk noodzakelijk is.</p>
            <p>De verwerking geschiedt met uitdrukkelijke toestemming van de betrokkene.</p>
            <p>De verwerking geschiedt op verzoek van een verzekeraar voor zover dat noodzakelijk is voor de beoordeling van het door de verzekeraar te verzekeren risico, dan wel voor zover dat noodzakelijk is voor de uitvoering van een verzekeringsovereenkomst.</p>
            <p>Zonder toestemming van de cliënt kunnen ten behoeve van statistiek of wetenschappelijk onderzoek op het gebied van de volksgezondheid aan een ander gegevens over de patiënt worden verstrekt indien:</p>
            <ul>
                <li>het vragen van toestemming in redelijkheid niet mogelijk is en met betrekking tot de uitvoering van het onderzoek is voorzien in zodanige waarborgen, dat de persoonlijke levenssfeer van de cliënt niet onevenredig wordt geschaad, of</li>                                
                <li>het vragen van toestemming, gelet op de aard en het doel van het onderzoek, in redelijkheid niet kan worden verlangd en de hulpverlener zorg heeft gedragen dat de gegevens in zodanige vorm worden verstrekt dat herleiding tot individuele personen redelijkerwijs wordt voorkomen. Verstrekking is pas mogelijk indien:</li>
                <li>het onderzoek het algemeen belang dient;</li>
                <li>het onderzoek niet zonder de desbetreffende gegevens kan worden uitgevoerd en voor zover de betrokken cliënt tegen een verstrekking niet uitdrukkelijk bezwaar heeft gemaakt.</li>
                <li>Het verbod om bijzondere gegevens als bedoeld in artikel 8 te verwerken is niet van toepassing voor zover dat noodzakelijk is in aanvulling op de verwerking van persoonsgegevens over iemands gezondheid met het oog op een goede behandeling of verzorging van de betrokkene.</li>
            </ul>
            <h3>Bijzondere persoonsgegevens</h3>
            <p>De verwerking van persoonsgegevens over iemands godsdienst of levensovertuiging, ras, politieke gezindheid, gezondheid, seksuele leven, het lidmaatschap van een vakvereniging of van strafrechtelijke persoonsgegevens is verboden, behalve in de gevallen waarin de wet uitdrukkelijk bepaalt door wie, met welk doel en onder welke voorwaarden dergelijke gegevens wel mogen worden verwerkt (artikelen 17 tot en met 22 van de wet).</p>
            <p>Het in het vorige lid bedoelde verbod geldt, onverminderd het bepaalde in de artikelen 17 tot en met 22 van de wet, niet voor zover er sprake is van een uitzondering zoals bedoeld in artikel 23 van de wet.</p>
            <h3>Gegevensverwerving</h3>
            <p>Gegevens verkregen bij betrokkene</p>
            <p>Indien bij de betrokkene zelf de persoonsgegevens worden verkregen, deelt de verantwoordelijke de betrokkene vóór het moment van verkrijging mede:</p>
            <ul>
                <li>zijn identiteit;</li>
                <li>het doel van de verwerking waarvoor de gegevens zijn bestemd, tenzij de betrokkene dat doel al kent.</li>
            </ul>
            <p>De verantwoordelijke verstrekt de betrokkene nadere informatie voor zover dat - gelet op de aard van de gegevens, de omstandigheden waaronder zij zijn verkregen of het gebruik dat ervan wordt gemaakt - nodig is om tegenover de betrokkene een behoorlijke en zorgvuldige verwerking te waarborgen.</p>
            <p>Bij verkrijging van gegevens buiten de betrokkene om deelt de verantwoordelijke de betrokkene mede:</p>
            <ul>
                <li>zijn identiteit;</li>
                <li>de aard van de gegevens en het doel van de verwerking waarvoor de gegevens zijn bestemd;</li>
            </ul>
            <p>Het moment waarop dat moet gebeuren is:</p>
            <ul>
                <li>het moment dat de verantwoordelijke de gegevens vastlegt of</li>
                <li>als de verantwoordelijke de gegevens uitsluitend verzamelt om deze aan een derde te verstrekken: uiterlijk op het moment van eerste verstrekking aan die derde</li>
            </ul>
            <p>De verantwoordelijke verstrekt nadere informatie voor zover dat - gelet op de aard van de gegevens, de omstandigheden waaronder zij worden verkregen of het gebruik dat ervan wordt gemaakt - nodig is om tegenover de betrokkene een behoorlijke en zorgvuldige verwerking te waarborgen.</p>
            <p>Het onder 3 bepaalde is niet van toepassing indien de daar bedoelde mededeling onmogelijk blijkt of een onevenredige inspanning kost. In dat geval legt de verantwoordelijke de herkomst van de gegevens vast.</p>
            <p>Het onder 3 bepaalde is evenmin van toepassing indien de vastlegging of verstrekking bij of krachtens de wet is voorgeschreven. In dat geval dient de verantwoordelijke de betrokkene op diens verzoek te informeren over het wettelijk voorschrift dat tot vastlegging of verstrekking van de betreffende gegevens heeft geleid.</p>
            <h3>Recht op inzage</h3>
            <p>De betrokkene heeft het recht kennis te nemen van de verwerkte gegevens die op zijn persoon betrekking hebben en mag hiervan een kopie ontvangen. De kosten die hieraan verbonden zijn staan gelijk aan het op dat moment geldende uurtarief.</p>
            <p>De verantwoordelijke deelt een ieder op diens verzoek - zo spoedig mogelijk maar uiterlijk binnen vier weken na ontvangst van het verzoek - schriftelijk mee of persoonsgegevens worden verwerkt die hem betreffen.</p>
            <p>Indien dat het geval is, verstrekt de verantwoordelijke de verzoeker desgewenst - zo spoedig mogelijk maar uiterlijk binnen vier weken na ontvangst van het verzoek - schriftelijk een volledig overzicht daarvan met informatie over het doel of de doelen van de gegevensverwerking, de gegevens of categorieën van gegevens waarop de verwerking betrekking heeft, de ontvangers of categorieën van ontvangers van de gegevens alsmede de herkomst van de gegevens.</p>
            <p>Indien een gewichtig belang van de verzoeker dit eist, voldoet de verantwoordelijke aan het verzoek in een andere dan de schriftelijke vorm die aan dat belang is aangepast.</p>
            <p>De verantwoordelijke kan weigeren aan een verzoek te voldoen indien en voor zover dit noodzakelijk is in verband met:</p>
            <ul>
                <li>de opsporing en vervolging van strafbare feiten;</li>
                <li>de bescherming van de betrokkene of van de rechten en vrijheden van anderen.</li>
            </ul>
            <h3>Verstrekking van persoonsgegevens</h3>
            <p>Verstrekking van persoonsgegevens aan een derde geschiedt in beginsel niet anders dan na toestemming van betrokkene of diens vertegenwoordiger, behoudens een daartoe strekkend wettelijk voorschrift of de noodtoestand.</p>
            <p>Indien verantwoordelijke zonder toestemming van betrokkene of diens wettelijk vertegenwoordiger persoonsgegevens aan derden verstrekt, stelt verantwoordelijk betrokkene of diens wettelijk vertegenwoordiger daarvan onverwijld in kennis, tenzij dit gevaar oplevert voor personen en of zaken.</p>
            <h3>Recht op correctie, aanvulling, verwijdering</h3>
            <p>Op schriftelijk verzoek van een betrokkene gaat de verantwoordelijke over tot verbetering, aanvulling, verwijdering en/of afscherming (het vergeetrecht) van de over de verzoeker verwerkte persoonsgegevens, indien en voor zover deze gegevens feitelijk onjuist, voor het doel van de verwerking onvolledig, niet ter zake dienend zijn of meer omvatten dan voor het doel van de registratie nodig is, dan wel anderszins in strijd met een wettelijk voorschrift worden verwerkt. Het verzoek van betrokkene bevat de aan te brengen wijzigingen.</p>
            <p>De verantwoordelijke deelt de verzoeker zo spoedig mogelijk, maar uiterlijk binnen vier weken na ontvangst van het verzoek, schriftelijk mee of hij daaraan voldoet. Indien hij daaraan niet of niet geheel wil voldoen, motiveert hij dat. Verzoeker heeft in dit verband de mogelijkheid zich te wenden tot de klachtencommissie van de verantwoordelijke.</p>
            <p>De verantwoordelijke draagt er zorg voor dat een beslissing tot verbetering, aanvulling, verwijdering en/of afscherming binnen 14 werkdagen, en wanneer dit redelijkerwijs niet mogelijk blijkt anders zo spoedig mogelijk nadien, wordt uitgevoerd.</p>
            <h3>Bewaren van gegevens</h3>
            <p>Persoonsgegevens worden niet langer bewaard in een vorm die het mogelijk maakt de betrokkene te identificeren, dan noodzakelijk is voor de realisatie van de doelen waarvoor zij worden verzameld of vervolgens worden bewerkt.</p>
            <p>De verantwoordelijke stelt vast hoelang de opgenomen persoonsgegevens bewaard blijven.</p>
            <p>De bewaartermijn voor medische- en/of zorggegevens is in beginsel vijftien jaren, te rekenen vanaf het tijdstip waarop zij zijn vervaardigd, of zoveel langer als redelijkerwijs uit de zorg van een goed hulpverlener of verantwoordelijke voortvloeit.</p>
            <p>Gegevens van niet medische aard worden niet langer bewaard dan noodzakelijk is voor de realisatie van de doelen waarvoor zij worden verzameld of vervolgens worden verwerkt, tenzij ze uitsluitend voor historische, statistische of wetenschappelijke doeleinden worden bewaard. Indien de desbetreffende gegevens zodanig zijn bewerkt dat herleiding tot individuele personen onmogelijk is, kunnen zij in geanonimiseerde vorm bewaard blijven.</p>
            <p>Indien de bewaartermijn van de persoonsgegevens is verstreken of de betrokkene een verzoek doet tot verwijdering vóór het verstrijken van de bewaartermijn, worden de desbetreffende medische gegevens binnen een termijn van drie maanden verwijderd.</p>
            <p>Verwijdering blijft echter achterwege wanneer redelijkerwijs is aan te nemen dat</p>
            <ul>
                <li>het bewaren van groot belang is voor een ander dan de betrokkene;</li>
                <li>het bewaren op grond van een wettelijk voorschrift vereist is of</li>
                <li>indien daarover tussen de betrokkene en de verantwoordelijke overeenstemming bestaat.</li>
            </ul>
            <h3>Melding van verwerking van gegevens</h3>
            <p>Een geheel of gedeeltelijk geautomatiseerde verwerking van persoonsgegevens die voor de realisatie van een doel of samenhangende doelen bestemd is, wordt aangemeld bij het College Bescherming Persoonsgegevens, voordat met de verwerking wordt begonnen.</p>
            <p>De niet geautomatiseerde verwerking van persoonsgegevens die voor de realisatie van een doel of samenhangende doelen is bestemd, wordt aangemeld indien deze is onderworpen aan voorafgaand onderzoek. Voorafgaand onderzoek vindt plaats indien de verantwoordelijke:</p>
            <ul>
                <li>van plan is een persoonsidentificatienummer te verwerken voor een ander doel dan waarvoor het bestemd is en om daardoor gegevens in verband te kunnen brengen met gegevens die worden verwerkt door een andere verantwoordelijke;</li>
                <li>van plan is gegevens op grond van eigen waarneming vast te leggen, zonder de verantwoordelijke daarvan op de hoogte te stellen;</li>
                <li>van plan is strafrechtelijke gegevens of gegevens over onrechtmatig of hinderlijk gedrag te verwerken ten behoeve van derden.</li>
            </ul>
            <p>In de melding staan aangegeven:</p>
            <ul>
                <li>de naam en het adres van de verantwoordelijke;</li>
                <li>het doel of de doelen van de verwerking;</li>
                <li>een beschrijving van de categorieën van betrokkenen en van de (categorieën van) gegevens die daarop betrekking hebben;</li>
                <li>de ontvangers of categorieën van ontvangers aan wie de gegevens kunnen worden verstrekt.</li>
            </ul>
            <h3>Klachten regeling</h3>
            <p>Wijzigingen in dit reglement worden aangebracht door de verantwoordelijke. De wijzigingen in het reglement zijn van kracht vier weken nadat ze bekend zijn gemaakt aan betrokkenen.</p>
            <p>Dit reglement treedt in werking per 25 mei 2018.</p>
            <p>Dit reglement is bij de verantwoordelijke in te zien. Desgewenst kan tegen kostprijs een afschrift van dit reglement worden verkregen.</p>
            <h3>Onvoorzien</h3>
            <p>In gevallen waarin dit reglement niet voorziet, beslist de verantwoordelijke, met inachtneming van het bepaalde in de wet en het doel en de strekking van dit reglement.</p>         
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialog = false"
          >
            Sluiten
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        dialog: false
    })
}
</script>

<style>

</style>