<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      dark
    >
      <div class="d-flex align-center">
        <!-- <h1><span class="burnout">burnout</span><span class="poli">poli</span></h1> -->
        <v-img
          max-height="150"
          max-width="215"
          src="../public/BOPtekst.jpg"
        ></v-img>
      </div>

      <v-spacer></v-spacer>

      <div
        v-if="progression <= 28"
        style="color:black;"
      >
        {{progression}} /28
      </div>

      <v-progress-linear
        :value="progressionBar"
        absolute
        bottom
        color="deep-purple accent-4"
      ></v-progress-linear>

    </v-app-bar>
    <v-main>
      <v-fade-transition
        mode="out-in"
      >
        <Questions
          v-if="!isDone"
          @progress="onProgress"
          @done="onDone"
        />
        <Result
          :scores="scores"
          v-else
        />
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
import Questions from './components/Questions';
import Result from './components/Result';

export default {
  name: 'App',

  components: {
    Questions,
    Result
  },

  data: () => ({
    progression: 1,
    progressionBar: 0,
    isDone: false,
    scores: 0
  }),
  methods: {
    onProgress(){
      const AMOUNT = 100/28;

      this.progression++;
      this.progressionBar += AMOUNT;
    },
    onDone(scores){
      this.scores = scores;
      this.isDone = true;
    }
  }
};
</script>

<style>
@font-face {
  font-family: ubuntuTitle;
  src: url('./assets/Ubuntu-Title.ttf') format('truetype');
}

.burnout{
  font-family: 'ubuntuTitle';
  color: #16B7F7;
}

.poli{
  font-family: Georgia,Times,Times New Roman,serif; 
  color: #7F5DA9;
}

h1, h2, h3, h4, h5 {
  color: #16B7F7;
  /* color:#7F5DA9; */
}

</style>