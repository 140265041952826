<template>
  <v-container fill-height>
    <v-row>
      <v-col cols="12">
        <v-container
          style="max-width:992px"
        >
          <v-row>
            <v-col
              cols="12"
              class="text-center mb-4"
            >
              <div>
                <transition mode="out-in" name="fade-in">
                  <span class="burnout question-text" 
                    :key="allQuestions[activeQuestionIndexTitle].title"
                    v-text="allQuestions[activeQuestionIndexTitle].title + ' '"
                  ></span>
                </transition>
                <transition mode="out-in" name="fade-in">
                  <span 
                    class="burnout question-text" 
                    :key="allQuestions[activeQuestionIndexTitle].questions[activeQuestionIndex].text" 
                    v-text="' '+allQuestions[activeQuestionIndexTitle].questions[activeQuestionIndex].text"
                  ></span>
                </transition>
              </div>
            </v-col>
            <v-col
              class="d-flex justify-space-around-custom justify-space-around-custom-sm"
            >
              <span></span>
              <v-btn
                class="ma-2"
                rounded
                dark
                color="#CD3C9E"
                @click="nextQuestion(allQuestions[activeQuestionIndexTitle].questions[activeQuestionIndex].reverse ? 4 : 1)"
              >
                Onwaar
              </v-btn>
              <v-btn
                class="ma-2"

                rounded
                color="#CD3C9E"
                dark
                @click="nextQuestion(allQuestions[activeQuestionIndexTitle].questions[activeQuestionIndex].reverse ? 3 : 2)"
              >
                Redelijk onwaar
              </v-btn>
              <v-btn
                class="ma-2"
                dark
                rounded
                color="#CD3C9E"

                @click="nextQuestion(allQuestions[activeQuestionIndexTitle].questions[activeQuestionIndex].reverse ? 2 : 3)"
              >
                Redelijk waar
              </v-btn>
              <v-btn
                class="ma-2"
                dark
                rounded
                color="#CD3C9E"

                @click="nextQuestion(allQuestions[activeQuestionIndexTitle].questions[activeQuestionIndex].reverse ? 1 : 4)"
              >
                Waar
              </v-btn>
              <span></span>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Questions',

    data: () => ({
      activeQuestionIndexTitle: 0,
      activeQuestionIndex: 0,
      allQuestions: [
          {title: 'Ik voel me de laatste tijd...', questions: [{text: 'zelfverzekerd', reverse: true, id:'T1-V1'}, {text: 'gemotiveerder dan voorheen', reverse: true, id: 'T1-V8'}, {text: 'paniekerig', reverse: false, id:'T1-V2'}, {text: 'down / neerslachtig', reverse: false ,id:'T1-V3'}, {text: 'alsof ik er niet meer toe doe in relaties',reverse: false, id:'T1-V4'}, {text: 'betrokken bij mensen om me heen',reverse: true,id:'T1-V5'}, {text: 'uitgeput', reverse: false, id:'T1-V6'}, {text: 'alsof ik het overzicht kwijt ben', reverse: false, id:'T1-V7'}], id: 'T1'},
          {title: 'Ik ', questions: [{text: 'voer mijn werk uit zoals ik wil', reverse: true, id: 'T2-V1'},{text: 'kan dagelijkse taken goed uitvoeren', reverse: true, id: 'T2-V2'},{text: 'maak vaker ruzie met mensen', reverse: false, id: 'T2-V3'},{text: 'vermijd mensen', reverse: false, id: 'T2-V4'},{text: 'vergeet sneller dingen', reverse: false, id: 'T2-V5'},{text: 'kan me goed concentreren', reverse: true , id: 'T2-V6'},{text: 'doe nogal onverschillig over veel dingen', reverse: false, id: 'T2-V7'}], id: 'T2'},
          {title: 'Ik ben ', questions: [{text: 'in staat om veranderingen makkelijk te accepteren', reverse: true, id: 'T3-V1'}, {text: 'perfectionstischer dan voorheen', reverse: false, id: 'T3-V2'}, {text: 'iemand met een groot verantwoordelijkheidsgevoel', reverse: false, id: 'T3-V3'}, {text: 'veel emotioneler dan voorheen', reverse: false, id: 'T3-V4'}, {text: 'sneller geïrriteerd dan voorheen',reverse: false, id: 'T3-V5'}, {text: 'meer gefocust op details dan voorheen',reverse: false, id: 'T3-V6'} ], id: 'T3'},
          {title: 'Ik wens dat ik ', questions: [{text: 'vrolijker ben',reverse: false,id: 'T4-V1'}, {text: 'wat meer ontspannen ben',reverse: false, id: 'T4-V2'}, {text: 'dingen makkelijker van me af kan zetten', reverse: false,id: 'T4-V3'}, {text: 'wat beter slaap', reverse: false,id: 'T4-V4'}, {text: 'wat makkelijker beslissingen neem',reverse: false, id: 'T4-V5'},{text: 'meer energie heb', reverse: false,id: 'T4-V6'}, {text: 'wat meer controle krijg',reverse: false, id: 'T4-V7'}], id:'T4'}
      ],
      
      scores: []
    }),
    methods: {
      nextQuestion(score){
        this.scores.push(score);
        this.$emit('progress');
        if((this.allQuestions[this.activeQuestionIndexTitle].questions.length) !== this.activeQuestionIndex+1){
          // theres still questions left, so increment question index
          this.activeQuestionIndex++;
        } else {
          // theres no questions left, cant increment question index
          if((this.allQuestions.length) !== this.activeQuestionIndexTitle+1){
            // can still increment title index
            this.activeQuestionIndexTitle++;
            // reset active question index
            this.activeQuestionIndex = 0;
          } else {
            // cant increment title index, the test is over
            this.$emit("done", this.scores)
          }
        }
      }
    }
  }
</script>

<style>
.fade-in-enter-active {
  transition: opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.fade-in-leave-active {
  transition: opacity 225ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
}

.question-text{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
  font-size: calc(3vw + 3vh);
}


@media (min-width: 960px){
  .justify-space-around-custom {
      justify-content: space-around !important;
  }
}
@media (max-width: 959px){
  .justify-space-around-custom-sm {
      flex-direction: column-reverse !important;
  }
}
</style>